<template>
  <div>
    <ProfileProjects />
  </div>
</template>

<script>
import ProfileProjects from "@/components/profile/Projects.vue";
export default {
  name: "Profile",
  components: {
    ProfileProjects
  }
};
</script>
