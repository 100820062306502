<template>
  <div class="profile">
    <sui-container>
      <sui-tab active-index="1">
        <sui-tab-pane active :title="$t('profile.savedProjects')">
          <ProjectsList />
        </sui-tab-pane>
        <sui-tab-pane :title="$t('profile.profile')">
          <UserProfile />
        </sui-tab-pane>
      </sui-tab>
    </sui-container>
  </div>
</template>

<script>
import ProjectsList from "@/components/profile/ProjectsList.vue";
import UserProfile from "@/components/profile/UserProfile.vue";
export default {
  name: "Profile",
  data() {
    return {
      orders: []
    };
  },
  components: {
    ProjectsList,
    UserProfile
  },
  beforeMount() {
    this.$store.dispatch("orders/getUserOrders").then(data => {
      this.orders = data;
    });
  }
};
</script>
