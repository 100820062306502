<template>
    <div class="UserProfile">
        <table class="ui very basic celled table">
            <tbody>
            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('banner.register.firstname') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.firstname }}
                </td>
            </tr>
            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('banner.register.lastname') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.lastname }}
                </td>
            </tr>

            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('banner.register.phone') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.phone }}
                </td>
            </tr>

            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            E-mail
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.email }}
                </td>
            </tr>
            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('banner.register.username') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.username }}
                </td>
            </tr>

            <tr v-if="currentUser.voivodeship">
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('banner.register.voivodeship') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.voivodeship }}
                </td>
            </tr>
            <tr>
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('profile.userType') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.userType }}
                </td>
            </tr>
            <tr v-if="profile.nip">
                <td class="collapsing">
                    <h4 class="ui image header">
                        <div class="content">
                            {{ $t('profile.nip') }}
                        </div>
                    </h4>
                </td>
                <td>
                    {{ currentUser.nip }}
                </td>
            </tr>
            </tbody>
        </table>
        <div class="ui stackable right aligned grid">
            <div class="four wide right floated column">
                <button class="ui button fluid" @click="showEditModal">
                    <i class="edit icon"></i>
                    {{ $t('profile.editProfile') }}
                </button>
            </div>
        </div>
        <!-- Modal -->
        <sui-modal v-model="editModal" size="small" v-if="editModal">
            <sui-modal-content>
                <div class="modal-top-bar">
                    <div class="text">{{ $t('profile.editProfile') }}</div>
                    <div class="cross" v-on:click="closeModal">x</div>
                </div>

                <form class="ui form">
                    <div class="ui stackable two column grid">
                        <div class="column">
                            <div class="field" :class="{ error: firstnameError }">
                                <label>{{ $t("banner.register.firstname") }}</label>
                                <input
                                    type="text"
                                    v-model="profile.firstname"
                                    :placeholder="$t('banner.register.firstname')"
                                    maxlength="30"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <div class="field" :class="{ error: lastnameError }">
                                <label>{{ $t("banner.register.lastname") }}</label>
                                <input
                                    type="text"
                                    v-model="profile.lastname"
                                    :placeholder="$t('banner.register.lastname')"
                                    maxlength="40"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <div class="field" :class="{ error: usernameError }">
                                <label>{{ $t("banner.register.phone") }}</label>
                                <input
                                    type="text"
                                    v-model="profile.phone"
                                    :placeholder="$t('banner.register.phone')"
                                    maxlength="30"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <div class="field" :class="{ error: emailError }">
                                <label>{{ $t("banner.register.email") }}</label>
                                <input
                                    type="email"
                                    v-model="profile.email"
                                    :placeholder="$t('banner.register.email')"
                                    maxlength="40"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="ui stackable one column grid">
                        <div class="column">
                            <div class="field" :class="{ error: usernameError }">
                                <label>{{ $t("banner.register.username") }}</label>
                                <input
                                    type="text"
                                    v-model="profile.username"
                                    :placeholder="$t('banner.register.username')"
                                    maxlength="30"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="ui stackable two column grid">
                        <div class="column" v-if="$root.$i18n.locale === 'pl'">
                            <div class="field">
                                <label>
                                    {{ $t("banner.register.voivodeship") }}
                                </label>
                                <sui-dropdown
                                    :placeholder="$t('banner.register.voivodeshipSelect')"
                                    selection
                                    :options="voivodeships"
                                    v-model="profile.voivodeship"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label>
                                    {{ $t("banner.register.userType") }}
                                </label>
                                <sui-dropdown
                                    :placeholder="$t('banner.register.userTypeSelect')"
                                    selection
                                    :options="userTypes"
                                    v-model="profile.userType"
                                    @input="checkUserType"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="ui stackable one column grid" v-if="showNip">
                        <div class="column">
                            <div class="field" :class="{ error: nipError }">
                                <label>{{ $t("banner.register.nip") }}</label>
                                <input type="text" :placeholder="$t('banner.register.nip')" v-model="profile.nip"/>
                            </div>
                        </div>
                    </div>

                    <div class="ui stackable two column grid">
                        <div class="column">
                            <div class="field" :class="{ error: newPasswordError }">
                                <label>{{ $t("banner.register.password") }}</label>
                                <input
                                    type="password"
                                    :placeholder="$t('banner.register.password')"
                                    v-model="newPassword"
                                    maxlength="30"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <div class="field" :class="{ error: editErrors.password }">
                                <label v-if="!editErrors.password">{{
                                        $t("banner.register.secondPassword")
                                    }}</label>
                                <label v-if="editErrors.password">{{
                                        $t("banner.register.errors.secondPassword")
                                    }}</label>
                                <input
                                    type="password"
                                    :placeholder="$t('banner.register.secondPassword')"
                                    v-model="newPasswordMatch"
                                    maxlength="30"
                                    @input="passwordValidator"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div class="ui loginButton">
                    <div class="ui equal width grid">
                        <div class="column">
                            <sui-button negative @click="editUser">
                                {{ $t('profile.save') }}
                            </sui-button>
                        </div>
                        <div class="column right floated right aligned">
                            <sui-button @click="hideEditModal">
                                {{ $t('profile.cancle') }}
                            </sui-button>
                        </div>
                    </div>
                </div>
            </sui-modal-content>
        </sui-modal>
    </div>
</template>

<script>
export default {
    name: "UserProfile",
    data() {
        return {
            profile: {},
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            nip: "",
            newPassword: "",
            newPasswordMatch: "",
            editModal: false,
            firstnameError: false,
            lastnameError: false,
            usernameError: false,
            emailError: false,
            newPasswordError: false,
            newPasswordMatchError: false,
            nipError: false,
            editErrors: [],
            showNip: false,
            userTypes: [
                {
                    text: this.$t("banner.register.wholesaler"),
                    value: "Hurtownia instalacyjna"
                },
                {
                    text: this.$t("banner.register.installer"),
                    value: "Instalator"
                },
                {
                    text: this.$t("banner.register.userPrivate"),
                    value: "Osoba prywatna"
                }
            ],
            voivodeships: [
                {
                    text: "dolnośląskie",
                    value: "dolnoslaskie"
                },
                {
                    text: "kujawsko-pomorskie",
                    value: "kujawsko-pomorskie"
                },
                {
                    text: "lubelskie",
                    value: "lubelskie"
                },
                {
                    text: "lubuskie",
                    value: "lubuskie"
                },
                {
                    text: "mazowieckie",
                    value: "mazowieckie"
                },
                {
                    text: "małopolskie",
                    value: "malopolskie"
                },
                {
                    text: "opolskie",
                    value: "opolskie"
                },
                {
                    text: "podkarpackie",
                    value: "podkarpackie"
                },
                {
                    text: "podlaskie",
                    value: "podlaskie"
                },
                {
                    text: "pomorskie",
                    value: "pomorskie"
                },
                {
                    text: "warmińsko-mazurskie",
                    value: "warmińsko-mazurskie"
                },
                {
                    text: "wielkopolskie",
                    value: "wielkopolskie"
                },
                {
                    text: "zachodniopomorskie",
                    value: "zachodniopomorskie"
                },
                {
                    text: "łódzkie",
                    value: "lodzkie"
                },
                {
                    text: "śląskie",
                    value: "slaskie"
                },
                {
                    text: "świętokrzyskie",
                    value: "swietokrzyskie"
                }
            ]
        };
    },
    mounted() {
        this.profile = {...this.$store.state.users.loggedUser};
        this.firstname = this.profile.firstname;
        this.lastname = this.profile.lastname;
        this.username = this.profile.username;
        this.email = this.profile.email;
        this.nip = this.profile.nip;
    },
    computed: {
        currentUser() {
            return this.$store.state.users.loggedUser || {};
        }
    },
    methods: {
        showEditModal: function () {
            this.profile = this.$store.state.users.loggedUser;
            this.firstname = this.profile.firstname;
            this.lastname = this.profile.lastname;
            this.username = this.profile.username;
            this.email = this.profile.email;
            this.nip = this.profile.nip;
            this.editModal = true;
            this.checkUserType();
        },
        hideEditModal: function () {
            this.editModal = false;
        },
        editUser: function () {
            let password = this.newPassword;
            password = password.trim();
            if (
                this.profile.firstname &&
                this.profile.lastname &&
                this.profile.username &&
                this.profile.email &&
                this.emailValidator()
            ) {
                if (password) {
                    this.profile.password = this.newPassword;
                }

                this.$store.dispatch("users/edit", this.profile).then(() => {
                    this.editModal = false;
                    this.newPassword = "";
                    this.newPasswordMatch = "";
                });

                this.firstnameError = false;
                this.lastnameError = false;
                this.usernameError = false;
                this.emailError = false;
                this.newPasswordError = false;
            } else {
                this.firstnameError = !this.profile.firstname;
                this.lastnameError = !this.profile.lastname;
                this.usernameError = !this.profile.username;
                this.emailError = !this.profile.email || !this.emailValidator();
            }
        },
        emailValidator: function () {
            // eslint-disable-next-line no-useless-escape
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.profile.email);
        },
        closeModal: function () {
            this.editModal = false;
        },
        checkUserType: function () {
            if (this.profile.userType === this.userTypes[0].value || this.profile.userType === this.userTypes[1].value) {
                this.showNip = true;
            } else {
                this.showNip = false;
            }
        },
        passwordValidator: function () {
            this.newPassword !== this.newPasswordMatch
                ? (this.editErrors.password = "error")
                : (this.editErrors.password = "");
        }
    }
};
</script>
